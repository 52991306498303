module.exports={
    "software": "软件",
    "download": "下载",
    "documentation": "文档",
    "wiki": "维基",
    "wiki-url": "https://zh.opensuse.org/",
    "forum": "论坛",
    "forum-url": "https://forum.suse.org.cn/",
    "development": "开发",
    "development-document": "开发文档",
    "development-document-url": "https://zh.opensuse.org/Portal:%E5%BC%80%E5%8F%91",
    "build-service": "构建服务 (OBS)",
    "information": "信息",
    "news": "新闻",
    "release-notes": "发行说明",
    "events": "活动",
    "planet": "星球",
    "shop": "商店",
    "community": "社群",
    "connect": "连接",
    "facebook-group": "脸书群",
    "google-group": "Google+ 群组",
    "mail-lists": "邮件列表",
    "irc-channels": "IRC 频道",
    "social-media": "社交媒体",
    "dark-mode": "暗色模式",
    "more": "更多",
    "search": "搜索",
    "main": "主站",
    "doc": "文档",
    "opensuse-universe": "openSUSE 宇宙",
    "main-site": "主站",
    "telegram-group": "电报群",
    "telegram-group-url": "https://t.me/opensuse_cn",
    "other": "其他",
    "guide-unofficial": "指南 (非官方)",
    "mirrors": "镜像",
    "lizards": "蜥蜴部落",
    "kernel": "内核",
    "status": "状态",
    "survey": "问卷"
}
