module.exports={
    "dark-mode": "Modalità Scura",
    "more": "Altro",
    "search": "Cerca",
    "main": "Principale",
    "software": "Software",
    "download": "Scarica",
    "doc": "Doc",
    "documentation": "Documentazione",
    "wiki": "Wiki",
    "wiki-url": "https://it.opensuse.org/",
    "forum": "Forum",
    "forum-url": "",
    "development": "Sviluppo",
    "development-document": "Documento",
    "development-document-url": "https://it.opensuse.org/Portal:Sviluppo",
    "build-service": "Servizio di compilazione",
    "information": "Informazione",
    "news": "Novità",
    "release-notes": "Note di rilascio",
    "events": "Eventi",
    "planet": "Pianeta",
    "shop": "Negozio",
    "community": "Comunità",
    "connect": "Connetti",
    "facebook-group": "Gruppo Facebook",
    "mail-lists": "",
    "irc-channels": "Canale IRC",
    "social-media": "",
    "opensuse-universe": "Universo openSUSE",
    "main-site": "Sito principale",
    "telegram-group": "Gruppo Telegram",
    "other": "Altro",
    "guide-unofficial": "Guide (non ufficiali)"
}
