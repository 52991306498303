module.exports={
    "software": "Programvara",
    "download": "Ladda ner",
    "documentation": "Dokumentation",
    "wiki": "Wiki",
    "wiki-url": "https://en.opensuse.org/",
    "forum": "Forum",
    "forum-url": "https://forums.opensuse.org/forumdisplay.php/842-English",
    "development": "Utveckling",
    "development-document": "Dokument",
    "development-document-url": "https://en.opensuse.org/Portal:Development",
    "build-service": "Byggtjänst",
    "information": "Information",
    "news": "Nyheter",
    "release-notes": "Viktig information",
    "events": "Evenemang",
    "planet": "Planet",
    "shop": "Butik",
    "community": "Community",
    "connect": "Anslut",
    "facebook-group": "Facebook grupp",
    "mail-lists": "Mailinglistor",
    "irc-channels": "IRC kanaler",
    "social-media": "Social media",
    "dark-mode": "Mörkt läge",
    "opensuse-universe": "openSUSE Universumet",
    "search": "Sök",
    "main": "Huvud",
    "main-site": "Huvudsida",
    "telegram-group": "Telegram grupp",
    "telegram-group-url": "https://t.me/opensuseusers",
    "other": "Annat",
    "guide-unofficial": "Guide (inofficiell)",
    "mirrors": "Speglar",
    "lizards": "Ödlor",
    "doc": "Doc"
}
