module.exports={
    "dark-mode": "حالت تاریک",
    "opensuse-universe": "جهان openSUSE",
    "search": "جستجو",
    "main": "اصلی",
    "main-site": "سایت اصلی",
    "software": "نرم‌افزار",
    "download": "دریافت",
    "doc": "مستندات",
    "documentation": "مستندات",
    "wiki": "ویکی",
    "wiki-url": "https://en.opensuse.org/",
    "forum": "انجمن",
    "forum-url": "https://forums.opensuse.org/forumdisplay.php/842-English",
    "development": "توسعه",
    "development-document": "سند",
    "development-document-url": "https://en.opensuse.org/Portal:Development",
    "build-service": "سرویس ساخت",
    "information": "اطلاعات",
    "news": "اخبار",
    "release-notes": "یادداشت‌های انتشار",
    "events": "رویداد‌ها",
    "planet": "سیاره",
    "shop": "فروشگاه",
    "community": "جامعه",
    "connect": "ارتباط",
    "facebook-group": "گروه فیس‌بوک",
    "telegram-group": "گروه تلگرام",
    "telegram-group-url": "https://t.me/openSUSE_group",
    "mail-lists": "لیست ایمیل",
    "irc-channels": "کانال‌های IRC",
    "social-media": "شبکه‌های اجتماعی",
    "other": "دیگر",
    "guide-unofficial": "راهنما (غیر رسمی)",
    "mirrors": "آینه‌ها",
    "lizards": "مارمولک‌ها"
}
