module.exports={
    "dark-mode": "Tume kujundus",
    "more": "Veel",
    "search": "Otsing",
    "main": "Peamine",
    "software": "Tarkvara",
    "download": "Allalaadimine",
    "doc": "Dokument",
    "documentation": "Dokumentatsioon",
    "wiki": "Wiki",
    "wiki-url": "https://en.opensuse.org/",
    "forum": "Foorum",
    "forum-url": "https://forums.opensuse.org/forum.php",
    "development": "Arendus",
    "development-document": "Dokument",
    "development-document-url": "https://en.opensuse.org/Portal:Development",
    "build-service": "Kompileerimise teenud",
    "information": "Informatsioon",
    "news": "Uudised",
    "release-notes": "Väljalaske teated",
    "events": "Sündmused",
    "planet": "Planeet",
    "shop": "Pood",
    "community": "Kommuun",
    "connect": "Ühenda",
    "facebook-group": "Facebooki grupp",
    "mail-lists": "Meililoend",
    "irc-channels": "IRC kanalid",
    "social-media": "Sotsiaalmeedia",
    "opensuse-universe": "openSUSE Universum",
    "main-site": "Esileht",
    "telegram-group": "Telegrammi grupp",
    "telegram-group-url": "https://t.me/opensuseusers",
    "other": "Muud",
    "guide-unofficial": "Juhend (mitteametlik)",
    "mirrors": "Peeglid",
    "lizards": "Lizard"
}
