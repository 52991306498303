module.exports={
    "software": "ソフトウエア",
    "download": "ダウンロード",
    "documentation": "ドキュメンテーション",
    "wiki": "Wiki",
    "wiki-url": "https://ja.opensuse.org/",
    "forum": "フォーラム",
    "forum-url": "https://forum.geeko.jp/",
    "development": "開発",
    "development-document": "文書",
    "development-document-url": "https://ja.opensuse.org/Portal:%E9%96%8B%E7%99%BA",
    "build-service": "Build サービス",
    "information": "情報",
    "news": "ニュース",
    "release-notes": "リリースノート",
    "events": "イベント",
    "planet": "Planet",
    "shop": "ショップ",
    "community": "コミュニティ",
    "connect": "Connect",
    "facebook-group": "Facebook グループ",
    "google-group": "Google+ グループ",
    "mail-lists": "メーリングリスト",
    "irc-channels": "IRC チャンネル",
    "social-media": "ソーシャルメディア",
    "dark-mode": "ダークモード",
    "more": "さらに",
    "search": "検索",
    "main": "メイン",
    "doc": "文書",
    "opensuse-universe": "openSUSE Universe",
    "main-site": "メインサイト",
    "telegram-group": "Telegram グループ",
    "telegram-group-url": "https://t.me/opensuseusers",
    "other": "その他",
    "guide-unofficial": "ガイド (非公式)",
    "mirrors": "ミラー",
    "lizards": "Lizards",
    "kernel": "カーネル",
    "status": "ステータス",
    "survey": "調査"
}
