module.exports={
    "dark-mode": "डार्क मोड",
    "opensuse-universe": "ओपनSUSE संसार",
    "search": "खोज",
    "main": "मुख्य",
    "main-site": "मुख्य साइट",
    "software": "सॉफ्टवेयर",
    "download": "डाउनलोड",
    "doc": "प्रलेख",
    "documentation": "प्रलेखन",
    "wiki": "विकी",
    "wiki-url": "https://en.opensuse.org/",
    "forum": "चर्चा मंच",
    "forum-url": "https://forums.opensuse.org/forumdisplay.php/842-English",
    "development": "सॉफ्टवेयर विकास",
    "development-document": "प्रलेख",
    "development-document-url": "https://en.opensuse.org/Portal:Development",
    "build-service": "बिल्ड सेवा",
    "kernel": "कर्नेल",
    "information": "जानकारी",
    "news": "समाचार",
    "release-notes": "प्रकाशन नोट्स",
    "events": "कार्यक्रम",
    "planet": "संसार",
    "shop": "दुकान",
    "status": "स्थिति",
    "survey": "सर्वेक्षण",
    "community": "समुदाय",
    "connect": "जुड़ें",
    "facebook-group": "फेसबुक समूह",
    "telegram-group": "टेलीग्राम समूह",
    "telegram-group-url": "https://t.me/opensuseusers",
    "mail-lists": "ईमेल सूची",
    "irc-channels": "आईआरसी चैट चैनल",
    "social-media": "सोशल मीडिया",
    "other": "अन्य",
    "guide-unofficial": "गाइड (अनाधिकारिक)",
    "mirrors": "सर्वर-मिरर",
    "lizards": "गिरगिट"
}
