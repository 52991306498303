module.exports={
    "software": "Программное обеспечение",
    "download": "Скачать",
    "documentation": "Документация",
    "wiki": "Вики",
    "wiki-url": "https://ru.opensuse.org/",
    "forum": "Форум",
    "forum-url": "http://forums.opensuse.org/forumdisplay.php/909-P%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9-(Russian)",
    "development": "Разработка",
    "development-document": "Документ",
    "development-document-url": "https://en.opensuse.org/Portal:Development",
    "build-service": "Служба сборки",
    "information": "Информация",
    "news": "Новости",
    "release-notes": "Примечания к выпуску",
    "events": "События",
    "planet": "Планета",
    "shop": "Магазин",
    "community": "Сообщество",
    "connect": "Connect",
    "facebook-group": "Группа на Facebook",
    "google-group": "Группа в Google+",
    "mail-lists": "Списки рассылки",
    "irc-channels": "IRC-каналы",
    "social-media": "Социальные сети",
    "dark-mode": "Тёмная тема",
    "opensuse-universe": "Вселенная openSUSE",
    "search": "Поиск",
    "main": "Главная",
    "main-site": "Основной сайт",
    "doc": "Документация",
    "telegram-group": "Группа Telegram",
    "telegram-group-url": "https://t.me/opensuseusers",
    "other": "Прочее",
    "guide-unofficial": "Руководство (неофициальное)",
    "mirrors": "Зеркала",
    "lizards": "Блоги",
    "kernel": "Ядро",
    "status": "Статус",
    "survey": "Опрос"
}
