module.exports={
  "dark-mode": "Dark Mode",
  "opensuse-universe": "openSUSE Universe",
  "search": "Search",
  "main": "Main",
  "main-site": "Main site",
  "software": "Software",
  "download": "Download",
  "doc": "Doc",
  "documentation": "Documentation",
  "wiki": "Wiki",
  "wiki-url": "https://en.opensuse.org/",
  "forum": "Forum",
  "forum-url": "https://forums.opensuse.org/forumdisplay.php/842-English",
  "development": "Development",
  "development-document": "Document",
  "development-document-url": "https://en.opensuse.org/Portal:Development",
  "build-service": "Build service",
  "kernel": "Kernel",
  "information": "Information",
  "news": "News",
  "release-notes": "Release notes",
  "events": "Events",
  "planet": "Planet",
  "shop": "Shop",
  "status": "Status",
  "survey": "Survey",
  "community": "Community",
  "connect": "Connect",
  "facebook-group": "Facebook group",
  "telegram-group": "Telegram group",
  "telegram-group-url": "https://t.me/opensuseusers",
  "mail-lists": "Mail lists",
  "irc-channels": "IRC channels",
  "social-media": "Social media",
  "other": "Other",
  "guide-unofficial": "Guide (unofficial)",
  "mirrors": "Mirrors",
  "lizards": "Lizards"
}
