module.exports={
    "dark-mode": "Tryb ciemny",
    "more": "",
    "search": "Szukaj",
    "main": "Główna",
    "software": "Oprogramowanie",
    "download": "Pobierz",
    "doc": "Dok",
    "documentation": "Dokumentacja",
    "wiki": "Wiki",
    "wiki-url": "https://pl.opensuse.org/",
    "forum": "Forum",
    "forum-url": "https://forums.opensuse.org/forumdisplay.php/936-Polski-(Polish)",
    "development": "Rozwój oprogramowania",
    "development-document": "Dokument",
    "development-document-url": "https://en.opensuse.org/Portal:Development",
    "build-service": "Usługa budowy",
    "information": "Informacje",
    "news": "Wiadomości",
    "release-notes": "Uwagi do wydania",
    "events": "Wydarzenia",
    "planet": "Planeta",
    "shop": "Sklep",
    "community": "Społeczność",
    "connect": "Połączenie",
    "facebook-group": "Grupa na Facebooku",
    "mail-lists": "Listy pocztowe",
    "irc-channels": "Kanały IRC",
    "social-media": "Media społecznościowe",
    "kernel": "Jądro",
    "survey": "Ankieta",
    "status": "Status",
    "mirrors": "Serwery lustrzane",
    "opensuse-universe": "openSUSE Universum",
    "main-site": "Strona główna",
    "telegram-group": "Grupa Telegram",
    "other": "Inne",
    "guide-unofficial": "Przewodnik (nieoficjalny)",
    "lizards": "Lizards"
}
