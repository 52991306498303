module.exports={
    "dark-mode": "Mørk tilstand",
    "more": "Mere",
    "search": "Søgning",
    "main": "Hoved",
    "software": "Software",
    "download": "Download",
    "doc": "Dok",
    "documentation": "Dokumentation",
    "wiki": "Wiki",
    "wiki-url": "https://en.opensuse.org/",
    "forum": "Forum",
    "forum-url": "https://forums.opensuse.org/forumdisplay.php/842-English",
    "development": "Udvikling",
    "development-document": "Dokument",
    "development-document-url": "https://en.opensuse.org/Portal:Development",
    "build-service": "Byggetjeneste",
    "information": "Information",
    "news": "Nyheder",
    "release-notes": "Udgivelsesnoter",
    "events": "Begivenheder",
    "planet": "Planet",
    "shop": "Butik",
    "community": "Fællesskab",
    "connect": "Vær med",
    "facebook-group": "Facebook-gruppe",
    "mail-lists": "Mailinglister",
    "irc-channels": "IRC-kanaler",
    "social-media": "Socialemedier",
    "opensuse-universe": "openSUSE Universe",
    "main-site": "Hovedsted",
    "telegram-group": "Telegram-gruppe",
    "telegram-group-url": "https://t.me/opensuseusers",
    "other": "Andet",
    "guide-unofficial": "Vejledning (uofficiel)",
    "mirrors": "Spejle",
    "lizards": "Øgler"
}
