module.exports={
    "software": "Software",
    "download": "Baixar",
    "documentation": "Documentação",
    "wiki": "Wiki",
    "wiki-url": "https://pt.opensuse.org/",
    "forum": "Fórum (apenas inglês)",
    "forum-url": "https://forums.opensuse.org/forumdisplay.php/842-English",
    "development": "Desenvolvimento",
    "development-document": "Documento",
    "development-document-url": "https://en.opensuse.org/Portal:Development",
    "build-service": "Serviço de compilação",
    "information": "Informação",
    "news": "Notícias",
    "release-notes": "Notas de lançamento",
    "events": "Eventos",
    "planet": "Planeta",
    "shop": "Loja",
    "community": "Comunidade",
    "connect": "Connect",
    "facebook-group": "Grupo no Facebook",
    "google-group": "Grupo no Google+",
    "mail-lists": "Listas de discussão",
    "irc-channels": "Canais IRC",
    "social-media": "Mídias sociais",
    "dark-mode": "Modo escuro",
    "more": "Mais",
    "search": "Pesquisa",
    "main": "Principal",
    "doc": "Doc",
    "opensuse-universe": "Universo openSUSE",
    "main-site": "Site principal",
    "telegram-group": "Grupo do Telegram",
    "telegram-group-url": "https://t.me/opensusebr",
    "other": "Outro",
    "guide-unofficial": "Guia (não-oficial)",
    "mirrors": "Espelhos",
    "lizards": "Lagartos",
    "kernel": "Kernel",
    "status": "Status",
    "survey": "Pesquisa"
}
