module.exports={
    "software": "Software",
    "download": "Descargar",
    "documentation": "Documentación",
    "wiki": "Wiki",
    "wiki-url": "https://es.opensuse.org",
    "forum": "Foro",
    "forum-url": "https://forums.opensuse.org/forumdisplay.php/837-Espa%C3%B1ol",
    "development": "Desarrollo",
    "development-document": "Documento",
    "development-document-url": "https://en.opensuse.org/Portal:Development",
    "build-service": "Servicio de compilaciones",
    "information": "Información",
    "news": "Noticias",
    "release-notes": "Informe de novedades",
    "events": "Eventos",
    "planet": "Planeta",
    "shop": "Tienda",
    "community": "Comunidad",
    "connect": "Conectar",
    "facebook-group": "Grupo de Facebook",
    "google-group": "Grupo de Google+",
    "mail-lists": "Listas de correo",
    "irc-channels": "Canales de IRC",
    "social-media": "Redes sociales",
    "dark-mode": "Modo oscuro",
    "opensuse-universe": "Universo openSUSE",
    "search": "Buscar",
    "main": "Principal",
    "main-site": "Sitio principal",
    "doc": "Doc",
    "telegram-group": "Grupo de Telegram",
    "telegram-group-url": "https://t.me/openSUSE_ES",
    "other": "Otro",
    "guide-unofficial": "Guía (no oficial)",
    "mirrors": "Espejos",
    "lizards": "Lagartos",
    "kernel": "Kernel",
    "status": "Estado",
    "survey": "Encuesta"
}
