module.exports={
    "software": "Software",
    "download": "Download",
    "documentation": "Dokumentation",
    "wiki": "Wiki",
    "wiki-url": "https://de.opensuse.org/",
    "forum": "Forum",
    "forum-url": "https://forums.opensuse.org/forumdisplay.php/845-German",
    "development": "Entwicklung",
    "development-document": "Dokument",
    "development-document-url": "https://de.opensuse.org/Portal:Entwicklung",
    "build-service": "Build Service",
    "information": "Informationen",
    "news": "Neuigkeiten",
    "release-notes": "Versionshinweise",
    "events": "Veranstaltungen",
    "planet": "Planet",
    "shop": "Shop",
    "community": "Community",
    "connect": "Connect",
    "facebook-group": "Facebook-Gruppe",
    "google-group": "Google+-Gruppe",
    "mail-lists": "Mailinglisten",
    "irc-channels": "IRC-Kanäle",
    "social-media": "Soziale Medien",
    "dark-mode": "Dunkler Modus",
    "more": "Mehr",
    "search": "Suche",
    "main": "Main",
    "doc": "Doc",
    "opensuse-universe": "openSUSE Universum",
    "main-site": "Hauptseite",
    "telegram-group": "Telegram-Gruppe",
    "telegram-group-url": "https://t.me/opensuseusers",
    "other": "Andere",
    "guide-unofficial": "Leitfaden (inoffiziell)",
    "mirrors": "Spiegelserver",
    "lizards": "Lizards",
    "kernel": "Kernel",
    "status": "Status",
    "survey": "Umfrage"
}
