module.exports={
    "dark-mode": "Tumma tila",
    "more": "Lisää",
    "search": "Etsi",
    "main": "Pääsivu",
    "software": "Ohjelmisto",
    "download": "Lataa",
    "doc": "Asiakirja",
    "documentation": "Dokumentointi",
    "wiki": "Wiki",
    "wiki-url": "https://en.opensuse.org/",
    "forum": "Foorumi",
    "forum-url": "https://forums.opensuse.org/forumdisplay.php/842-English",
    "development": "Tuotekehitys",
    "development-document": "Asiakirja",
    "development-document-url": "https://en.opensuse.org/Portal:Development",
    "build-service": "Rakenna palvelu",
    "information": "Informaatio",
    "news": "Uutiset",
    "release-notes": "Julkaisutiedot",
    "events": "Tapahtumat",
    "planet": "Planeetta",
    "shop": "Kauppa",
    "community": "Yhteisö",
    "connect": "Yhdistä",
    "facebook-group": "Facebook ryhmä",
    "mail-lists": "Postituslistat",
    "irc-channels": "IRC kanavat",
    "social-media": "Sosiaalinen media",
    "main-site": "Pääsivusto",
    "telegram-group": "Telegram ryhmä",
    "other": "Muut",
    "guide-unofficial": "Ohje (epävirallinen)",
    "mirrors": "Peilit",
    "opensuse-universe": "openSUSE-universumi",
    "telegram-group-url": "https://t.me/opensuseusers",
    "lizards": "Liskot",
    "status": "Tila",
    "survey": "Selvitys",
    "kernel": "Ydin"
}
