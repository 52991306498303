module.exports={
    "software": "軟體",
    "download": "下載",
    "documentation": "文件",
    "wiki": "Wiki",
    "wiki-url": "https://zh-tw.opensuse.org/",
    "forum": "論壇",
    "forum-url": "https://forum.suse.org.cn/",
    "development": "開發",
    "development-document": "開發文件",
    "development-document-url": "https://en.opensuse.org/Portal:Development",
    "build-service": "建構服務 (OBS)",
    "information": "資訊",
    "news": "新聞",
    "release-notes": "發行紀錄",
    "events": "活動",
    "planet": "星球",
    "shop": "商店",
    "community": "社群",
    "connect": "連線",
    "facebook-group": "Facebook 群組",
    "google-group": "Google+ 群組",
    "mail-lists": "郵件列表",
    "irc-channels": "IRC 頻道",
    "social-media": "社群媒體",
    "dark-mode": "暗色模式",
    "more": "更多",
    "search": "搜尋",
    "main": "主要站台",
    "doc": "文件",
    "main-site": "主站",
    "kernel": "內核",
    "status": "狀態",
    "survey": "問卷",
    "telegram-group": "Telegram 群組",
    "telegram-group-url": "https://t.me/opensuseusers",
    "other": "其他",
    "guide-unofficial": "指南 (非官方)",
    "mirrors": "鏡像站台"
}
